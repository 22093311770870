import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  JsonService
} from "./json-service";
import {
  RestService
} from "./rest-service";

@autoinject
export class NotificationService {
  private _socket: WebSocket;
  private _manualClose: boolean;
  private _intervalId: any;

  constructor(
    private eventAggregator: EventAggregator,
    private json: JsonService,
    private rest: RestService
  ) { }

  subscribe(): void {
    this._manualClose = false;
    this._socket = new WebSocket(this.rest.getWebSocketUrl("base/Notification/Socket"));

    this._socket.onmessage = (e) => {
      const data = this.json.parse(e.data);

      this.eventAggregator.publish(data.Type, data.Data);
    };
    this._socket.onclose = (e) => {
      if (!this._manualClose) {
        setTimeout(this.tryToReconnect.bind(this), 6000);
      }

      this._socket = null;
      this._manualClose = false;
    };

    this._intervalId = setInterval(() => {
      if (!this._socket) {
        return;
      }
      if (this._socket.readyState != this._socket.OPEN) {
        return;
      }

      this._socket.send("PING");
      },
      60000);
  }

  unsubscribe(): void {
    clearInterval(this._intervalId);

    if (this._socket) {
      this._manualClose = true;
      this._socket.close();
    }
  }

  private tryToReconnect(): void {
    clearInterval(this._intervalId);

    if (this._socket == void 0) {
      this.subscribe();
      return;
    }

    this._socket.close();
  }
}
