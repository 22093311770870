import { autoinject } from "aurelia-framework";
import { StartupService } from "./startup-service";
import { IModul } from "../interfaces/modul";

@autoinject
export class ModuleService {
  private _moduleInfos: IModuleInfo[];
  private _berechtModules: IModul[];

  constructor(
    private startupService: StartupService) {
    this.registerModules();
  }

  canActivateModule(moduleId: string) {
    const startupInfo = this.startupService.startupInfo;

    const isCloudAdministrator = startupInfo
      && startupInfo.Benutzer
      && startupInfo.Benutzer.IsCloudAdministrator;

    const isAdministrator = startupInfo
      && startupInfo.Benutzer
      && startupInfo.Benutzer.IsAdministrator;

    const isSupport = startupInfo.Benutzer.IsSupport;

    const moduleInfo = this._moduleInfos.find((m) =>
      moduleId.toLowerCase()
        .startsWith(m.moduleIdStart));
    if (!moduleInfo) {
      return !isCloudAdministrator;
    }

    if (!this.hasLizenz(moduleInfo)) {
      return false;
    }

    if ((isCloudAdministrator || false) != (moduleInfo.hasOnlyCloudAdmin || false)) {
      //Ausnahme wenn IsCloudAdministrator und hasCloudAdmin
      if (!(isCloudAdministrator && moduleInfo.hasCloudAdmin)) {
        return false;
      } else if (isCloudAdministrator && moduleInfo.hasCloudAdmin) {
        return true;
      }
    }

    if (!this.hasBerechtigung(moduleId)) {
      return false;
    }

    if (!isAdministrator && !isCloudAdministrator) {
      if (moduleInfo.hasOnlyAdmin) {
        return false;
      }
    }

    if (!isSupport && moduleInfo.hasOnlySupport) {
      return false;
    }

    return true;
  }
  resetBerechtModules() {
    this._berechtModules = this.startupService.startupInfo.ModulList
      .map((c) => c)
      .sort((a, b) => b.Modul.localeCompare(a.Modul));
  }

  hasBerechtigungProjektzeit() {
    return this.hasBerechtigung("erp/zeiterfassung");
  }
  hasBerechtigungArbeitszeit() {
    return this.hasBerechtigung("erp/arbeitszeit");
  }
  hasBerechtigungDashboardUnternehmen() {
    return this.hasBerechtigung("erp/dashboard-unternehmen");
  }

  private hasBerechtigung(moduleId: string) {
    if (this.startupService.startupInfo.Benutzer.IsAdministrator) {
      return true;
    }

    const module = this._berechtModules.find((m) =>
      moduleId.toLowerCase()
        .startsWith(m.Modul));
    if (!module) {
      return true;
    }
    if (!module.HasBerechtigung) {
      return false;
    }

    return true;
  }
  private hasLizenz(moduleInfo: IModuleInfo) {
    const lizenz = this.startupService.startupInfo.Lizenz;

    const needsLizenz = moduleInfo.hasEinkaufVerkauf
      || moduleInfo.hasMailImport
      || moduleInfo.hasNewsletter
      || moduleInfo.hasProduktion
      || moduleInfo.hasSkript
      || moduleInfo.hasWebshop
      || moduleInfo.HasWooCommerce
      || moduleInfo.hasKassa
      || moduleInfo.hasMahnung
      || moduleInfo.hasFibu
      || moduleInfo.hasProjektzeiterfassung
      || moduleInfo.hasLadeliste
      || moduleInfo.hasArtikelverleih
      || moduleInfo.hasBelegImportExport
      || moduleInfo.hasProjektplanung
      || moduleInfo.hasIntrastat
      || moduleInfo.hasProjektzeitverrechnung
      || moduleInfo.hasVariablerSetartikel
      || moduleInfo.hasArbeitszeit
      || moduleInfo.hasIntercompany
      || moduleInfo.hasZahlungsverkehr
      || moduleInfo.hasOSS
      || moduleInfo.hasLagerOhneMHDCharge;

    if (!needsLizenz) {
      return true;
    }

    return (moduleInfo.hasEinkaufVerkauf && lizenz.HasEinkaufVerkauf)
      || (moduleInfo.hasMailImport && lizenz.HasMailImport)
      || (moduleInfo.hasNewsletter && lizenz.HasNewsletter)
      || (moduleInfo.hasProduktion && lizenz.HasProduktion)
      || (moduleInfo.hasProvision && lizenz.HasProvision)
      || (moduleInfo.hasSkript && lizenz.HasSkript)
      || (moduleInfo.hasWebshop && lizenz.HasWebshop)
      || (moduleInfo.HasWooCommerce && lizenz.HasWooCommerce)
      || (moduleInfo.hasKassa && lizenz.HasKassa)
      || (moduleInfo.hasMahnung && lizenz.HasMahnung)
      || (moduleInfo.hasFibu && lizenz.HasFibu)
      || (moduleInfo.hasLadeliste && lizenz.HasLadeliste)
      || (moduleInfo.hasArtikelverleih && lizenz.HasArtikelverleih)
      || (moduleInfo.hasBelegImportExport && lizenz.HasBelegImportExport)
      || (moduleInfo.hasProjektzeiterfassung && lizenz.HasProjektzeiterfassung)
      || (moduleInfo.hasProjektplanung && lizenz.HasProjektplanung)
      || (moduleInfo.hasIntrastat && lizenz.HasIntrastat)
      || (moduleInfo.hasProjektzeitverrechnung && lizenz.HasProjektzeitverrechnung)
      || (moduleInfo.hasVariablerSetartikel && lizenz.HasVariablerSetartikel)
      || (moduleInfo.hasArbeitszeit && lizenz.HasArbeitszeit)
      || (moduleInfo.hasIntercompany && lizenz.HasIntercompany)
      || (moduleInfo.hasZahlungsverkehr && lizenz.HasZahlungsverkehr)
      || (moduleInfo.hasOSS && lizenz.HasOSS)
      || (moduleInfo.hasLagerOhneMHDCharge && lizenz.HasLagerOhneMHDCharge)
  }
  private registerModules() {
    const moduleInfos: IModuleInfo[] = [];

    moduleInfos.push({ moduleIdStart: "erp/views/dashboard-designer", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/anfrageart", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/angebotsart", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/anrede", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/arbeitszeit-benutzer-zuteilung", hasArbeitszeit: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/arbeitszeit-kostenstelle", hasArbeitszeit: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/artikelart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/artikelgruppe", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/artikelkategorie", hasBenutzerrolle: true, hasKassa: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/auswertung", hasBenutzerrolle: true, hasOnlySupport: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/auftragsart", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/benutzer", hasBenutzerrolle: true, hasCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/benutzerrolle", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/bestellungsart", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/briefvorlage", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/clientserviceeinstellung", hasOnlySupport: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/dokumentenart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/druck-einstellung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/drucktext", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/einheit", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/faktura-sammelart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/filiale", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/filtergruppe", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/firma", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/firma-zahlungsverkehr", hasBenutzerrolle: true, hasZahlungsverkehr: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/geschaeftspartnerart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/intercompany", hasBenutzerrolle: true, hasIntercompany: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/intrastat-sync", hasBenutzerrolle: true, hasIntrastat: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/kalender", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/kassa", hasBenutzerrolle: true, hasKassa: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/lager", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/lagerbuchungsart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/land", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/lieferbedingung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/mail-import-item", hasBenutzerrolle: true, hasMailImport: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/mail-import-def", hasMailImport: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/mail-server", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/mail-vorlage", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/mandant", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/medien", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/newsletter", hasBenutzerrolle: true, hasNewsletter: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/notiztyp", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/nummernkreis", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/papierkorb", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/personenaktion", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/personenfunktion", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/positionsart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/projektart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/provisionsmodell", hasProvision: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/qr-label", hasEinkaufVerkauf: true, hasBelegImportExport: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/reklamationsgrund", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/skript-item", hasSkript: true, hasOnlySupport: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/spediteur", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/sprache", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/status", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/steuercode", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/steuersatz", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/tag", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/taetigkeit", hasBenutzerrolle: true, hasProjektzeiterfassung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/taetigkeitenart", hasBenutzerrolle: true, hasProjektzeiterfassung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/textbaustein", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/uid-validierung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/validierung", hasSkript: true, hasOnlySupport: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/vertreter", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/waehrung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/zahlungsart", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/zahlungsbedingung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/stammdaten/zuschlagsart", hasEinkaufVerkauf: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/suche/volltext-thesaurus", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/dynamische-felder", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/kalkulation/berechnungsdefinition", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/kalkulation/konditionsfeld", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/kalkulation/kondition", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/beleg-import", hasBelegImportExport: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/fibu-konto", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/fibu-oss", hasFibu: true, hasOSS: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/fibu-sync-job", hasFibu: true, hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/fehler", hasOnlyCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/instanz", hasOnlyCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/import", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/einstellung", hasOnlyCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/datenbank-info", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration/status", hasOnlyCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/konfiguration", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/einkauf/bestellung", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/einkauf/bestellvorschlag", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/einkauf/warenuebernahme", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/einkauf/eingangsrechnung", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/lager/inventur", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/lager/lagerkorrektur", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/lager/artikelverleih", hasBenutzerrolle: true, hasArtikelverleih: true });
    moduleInfos.push({ moduleIdStart: "erp/views/produktion", hasBenutzerrolle: true, hasProduktion: true });
    moduleInfos.push({ moduleIdStart: "erp/views/kassa/kassa-beleg", hasBenutzerrolle: true, hasKassa: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/angebot", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/auftrag", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/lieferschein", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/ladeliste", hasBenutzerrolle: true, hasLadeliste: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/faktura", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/kundenreklamation", hasBenutzerrolle: true, hasEinkaufVerkauf: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/provision", hasBenutzerrolle: true, hasProvision: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/gutschein", hasBenutzerrolle: true, hasKassa: true, HasWooCommerce: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/mahnung", hasBenutzerrolle: true, hasMahnung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/verkauf/zahlung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/tickets", hasCloudAdmin: true });
    moduleInfos.push({ moduleIdStart: "erp/views/webshop", hasBenutzerrolle: true, hasWebshop: true });
    moduleInfos.push({ moduleIdStart: "erp/views/zeiterfassung/projekt-taetigkeit-buchung", hasBenutzerrolle: true, hasProjektzeiterfassung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/zeiterfassung/projektplanung", hasBenutzerrolle: true, hasProjektplanung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-artikel", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-auftrag", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-bestellung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-ein-auszahlungs-rechnung", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-faktura", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-webshop-login-log", hasBenutzerrolle: true, hasWebshop: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-geschaeftspartner-person", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-gruppe", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-kassa-info", hasBenutzerrolle: true, hasKassa: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-kunde", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-lagerbestand", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-lagerbewegungen", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-reklamationen", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/statistik-projektzeit", hasBenutzerrolle: true, hasProjektzeiterfassung: true });
    moduleInfos.push({ moduleIdStart: "erp/views/statistik/uebersicht", hasBenutzerrolle: true });
    moduleInfos.push({ moduleIdStart: "erp/views/arbeitszeit/benutzerstatus", hasBenutzerrolle: true, hasArbeitszeit: true });
    moduleInfos.push({ moduleIdStart: "erp/views/arbeitszeit/saldenuebersicht", hasBenutzerrolle: true, hasArbeitszeit: true });
    moduleInfos.push({ moduleIdStart: "erp/views/arbeitszeit/lohnexport", hasBenutzerrolle: true, hasArbeitszeit: true });
    moduleInfos.push({ moduleIdStart: "erp/views/arbeitszeit/tagesbuchungen", hasBenutzerrolle: true, hasArbeitszeit: true });
    moduleInfos.push({ moduleIdStart: "erp/views/zahlungsverkehr/zahlungsverkehr", hasBenutzerrolle: true, hasZahlungsverkehr: true });

    this._moduleInfos = moduleInfos.sort((a, b) => b.moduleIdStart.localeCompare(a.moduleIdStart));
  }
}

interface IModuleInfo {
  moduleIdStart: string;
  hasWebshop?: boolean;
  hasProduktion?: boolean;
  hasNewsletter?: boolean;
  hasEinkaufVerkauf?: boolean;
  hasMailImport?: boolean;
  hasProvision?: boolean;
  hasSkript?: boolean;
  hasKassa?: boolean;
  hasMahnung?: boolean;
  HasWooCommerce?: boolean;
  hasLadeliste?: boolean;
  hasFibu?: boolean;
  hasProjektzeiterfassung?: boolean;
  hasArtikelverleih?: boolean;
  hasBelegImportExport?: boolean;
  hasOnlyAdmin?: boolean;
  hasOnlyCloudAdmin?: boolean;
  hasOnlySupport?: boolean;
  hasCloudAdmin?: boolean;
  hasBenutzerrolle?: boolean;
  hasProjektplanung?: boolean;
  hasIntrastat?: boolean;
  hasProjektzeitverrechnung?: boolean;
  hasVariablerSetartikel?:boolean
  hasArbeitszeit?: boolean;
  hasIntercompany?: boolean;
  hasZahlungsverkehr?: boolean;
  hasOSS?: boolean;
  hasLagerOhneMHDCharge?: boolean;
}
