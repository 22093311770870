import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase,
} from "../../../../framework/forms/export";
import {
  VorbGueltigBis,
  VorbGueltigVon
} from "../../../enumerations/export";
import {
  KonditionService
} from "../../../services/export";
import * as moment from "moment";
import { LocalizationService, RestService } from '../../../../framework/base/export';
import { IdxNumberBoxComponent } from '../../../interfaces/export';

@autoinject
@transient()
export class KonditionEditUtils {
  constructor(
    private kondition: KonditionService,
    private localizationService: LocalizationService,
    private _restService: RestService) { }

  form: FormBase;
  currentKonditionsfeldOptions: any = {};
  geschaeftspartnerInfoDataSource: DevExpress.data.DataSource;
  artikelInfoDataSource: DevExpress.data.DataSource;


  geschaeftspartnerInfoSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    showClearButton: true,
    bindingOptions: {
      disabled: "variables.data.$v_idGeschaeftspartner",
      value: "models.data.$m_Kondition.IdGeschaeftspartnerInfo",
    },
    itemTemplate: (data) => {
      return this.getSelectBoxItemTemplate(data);
    },
    
    fieldTemplate: "erp--cbo-type-bezeichnung-field-template"
  };

  artikelInfoSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    showClearButton: true,
    bindingOptions: {
      disabled: "variables.data.$v_idArtikel",
      value: "models.data.$m_Kondition.IdArtikelInfo",
    },
    itemTemplate: (data) => {
      return this.getSelectBoxItemTemplate(data);
    },
    fieldTemplate: "erp--cbo-type-bezeichnung-field-template"
  };

  artikelInfoRequiredRule: DevExpress.ui.CustomRule = {
    type: "custom",
    message: this.localizationService.translateOnce("forms.validator_required", ["Artikel"]),
    reevaluate: true,
    validationCallback: (e) => {
      if (!this.currentKonditionsfeldOptions) {
        return true;
      }

      if (!this.currentKonditionsfeldOptions.IsArtikelPflicht) {
        return true;
      }

      const kondition = this.form.models.data.$m_Kondition;
      if (!kondition) {
        return true;
      }
      if (kondition.IdArtikelInfo) {
        return true;
      }

      return false;
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.callOnBind(() => {
      form["r_konditionsfeldOptions"].onValueChangedByUser = (e) => {
        this.currentKonditionsfeldOptions = e.value
          ? this.kondition.getKonditionFeldById(e.value)
          : {};

        this.onKonditionsFeldChanged(e.value);
        this.updateWertFormat();
      };
    });
    form.models.onLoaded.register(async r => {
      if (r.model.id !== "$m_Kondition") {
        return;
      }
      if (!r.data) {
        return;
      }

      this.currentKonditionsfeldOptions = r.data.IdKonditionsfeld
        ? this.kondition.getKonditionFeldById(r.data.IdKonditionsfeld)
        : {};

      if (!r.data.Id) {
        if (form.variables.data.$v_idArtikel) {
          r.data.IdArtikelInfo = form.variables.data.$v_idArtikel;
        }
        if (form.variables.data.$v_idGeschaeftspartner) {
          r.data.IdGeschaeftspartnerInfo = form.variables.data.$v_idGeschaeftspartner;
        }
      }

      this.updateWertFormat();
    });

    this.geschaeftspartnerInfoDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
      cacheRawData: true,
      key: "Id",
      byKey: async (key) => {
        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/Kondition/KonditionGpArtikelInfo"),
          data: {
            Suchtext: null,
            TypeName: "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner",
            Id: key,
            skip: 0,
            take: 1
          }
        });

        return r.ItemList;
      },
      load: async (loadOptions) => {
        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/Kondition/KonditionGpArtikelInfo"),
          data: {
            Suchtext: loadOptions.searchValue,
            TypeName: "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner",
            Id: null,
            skip: loadOptions.skip,
            take: loadOptions.take
          }
        });

        return r.ItemList;
      }
    }));
    this.artikelInfoDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
      cacheRawData: true,
      key: "Id",
      byKey: async (key) => {
        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/Kondition/KonditionGpArtikelInfo"),
          data: {
            Suchtext: null,
            TypeName: "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel",
            Id: key,
            skip: 0,
            take: 1
          }
        });

        return r.ItemList;
      },
      load: async (loadOptions) => {
        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/Kondition/KonditionGpArtikelInfo"),
          data: {
            Suchtext: loadOptions.searchValue,
            TypeName: "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel",
            Id: null,
            skip: loadOptions.skip,
            take: loadOptions.take
          }
        });

        return r.ItemList;
      }
    }));

    this.artikelInfoSelectBoxOptions.dataSource = this.artikelInfoDataSource;
    this.geschaeftspartnerInfoSelectBoxOptions.dataSource = this.geschaeftspartnerInfoDataSource;
  }

  private getVorbelegungVonDatum(gueltigkeit: VorbGueltigVon): Date {
    if (gueltigkeit == void 0) {
      return null;
    }

    let result: moment.Moment;

    switch (gueltigkeit) {
      case VorbGueltigVon.Min:
        result = moment("01-01-2000", "DD-MM-YYYY");
        break;
      case VorbGueltigVon.Heute:
        result = moment().startOf("day");
        break;
      case VorbGueltigVon.Monatsbeginn:
        result = moment().startOf("month");
        break;
      case VorbGueltigVon.Jahresbeginn:
        result = moment().startOf("year");
        break;
    }

    return result ? result.toDate() : null;
  }
  private getVorbelegungBisDatum(gueltigkeit: VorbGueltigBis): Date {
    if (gueltigkeit == void 0) {
      return null;
    }
    let result: moment.Moment;

    switch (gueltigkeit) {
      case VorbGueltigBis.Monatsende:
        result = moment().endOf("month");
        break;
      case VorbGueltigBis.Jahresende:
        result = moment().endOf("year");
        break;
      case VorbGueltigBis.Max:
        result = moment("31-12-2099", "DD-MM-YYYY");
        break;
    }

    return result ? result.toDate() : null;
  }

  private setKonditionfeldOptions(konditionsfeld: any) {
    const kondition = this.form.models.data.$m_Kondition;

    if (konditionsfeld == void 0 || kondition == void 0) {
      return;
    }

    kondition.IdWaehrung = konditionsfeld.IdVorbWaehrung;
    kondition.Staffel = konditionsfeld.VorbStaffel;
    kondition.Wert = konditionsfeld.VorbWert;
    kondition.WertPer = konditionsfeld.VorbWertPer;
    kondition.GueltigVon = this.getVorbelegungVonDatum(konditionsfeld.VorbGueltigVon);
    kondition.GueltigBis = this.getVorbelegungBisDatum(konditionsfeld.VorbGueltigBis);
  }

  private updateWertFormat() {
    const anzKommastellen = !!this.currentKonditionsfeldOptions && this.currentKonditionsfeldOptions.AnzKommastellen != void (0)
      ? this.currentKonditionsfeldOptions.AnzKommastellen
      : 2;

    let format = "#,##0";

    if (anzKommastellen > 0) {
      format += ".".concat("0".repeat(anzKommastellen));
    }

    const options = {
      format: format
    };

    if (this.form["r_wert"]) {
      (<IdxNumberBoxComponent>this.form["r_wert"]).setOption(options);
    } else {
      Object.assign(this.form["r_wertOptions"], options);
    }
  }

  private onKonditionsFeldChanged(newValue: number) {
    this.setKonditionfeldOptions(this.currentKonditionsfeldOptions);
  }

  private getSelectBoxItemTemplate(data) {
    return `<div class="erp--text-with-leading-label-badge erp--margin-top-left-2px">
        <div class="${data.TypeBezeichnung ? '' : 'erp--empty-badge'}">
          ${data.TypeBezeichnung}
        </div>
        <div class="erp--margin-right-36px">
          ${data.CboBezeichnung}
        </div>
      </div>`;
  }
};
