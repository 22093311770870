import { autoinject, bindable, observable } from "aurelia-framework";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import { IdxDataGridComponent } from "../../interfaces/dx-components";

@autoinject
export class ArbeitszeitRuhendstellungZuteilungList {

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  @bindable @observable mitarbeiterstatus: IArbeitszeitMitarbeiterstatus;

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnResizing: true,
    columnResizingMode: "widget",
    paging: {
      pageSize: 20
    },
    hoverStateEnabled: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columns: [{
      dataField: "GueltigVon",
      caption: "Gültig von",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "GueltigBis",
      caption: "Gültig bis",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    }]
  };

  mitarbeiterstatusChanged(e) {
    if (this.mitarbeiterstatus != null && this.mitarbeiterstatus.IdMitarbeiterstatus > 0) {
      this.loadMitarbeiterstatusRuhendstellungZuteilung();
    }
  }

  private async loadMitarbeiterstatusRuhendstellungZuteilung() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/Ruhendstellung/${this.mitarbeiterstatus.IdMitarbeiterstatus}`)
    });

    this.grid.instance.option("dataSource", r);
  }
}
