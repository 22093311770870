import { autoinject } from "aurelia-framework";
import { RestService } from "./rest-service";

@autoinject
export class LoginUrlService {

  constructor(
    private _restService: RestService) { }

  async init() {
    this._restService.setBaseUrl(this.getUrlOfSuffix());
  }

  private getUrlOfSuffix() {
    if (location.hostname.includes("-intern.")) {
      return "https://api-intern.clue.services";
    } else if (location.hostname.includes("clue.services")) {
      return "https://api.clue.services";
    } else {
      return "http://localhost:5073";
    }
  }
}
